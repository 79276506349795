import React, { useState, useRef } from 'react'
import { graphql, Link } from 'gatsby'
import Image from 'gatsby-image'
import styled from 'styled-components'
import qs from 'qs'

import SEO from '../components/SEO'
import FullWidthWrapper from '../components/FullWidthWrapper'
import ForegroundLayout from '../components/ForegroundLayout'
import MediumHeading from '../components/MediumHeading'
import AngledDivider from '../components/AngledDivider'
import ContentBlock from '../components/ContentBlock'
import Overlay from '../components/Overlay'
import FlexBox from '../components/FlexBox'
import WordArt from '../components/WordArt'
import Paragraph from '../components/Paragraph'
import pdfIcon from '../graphics/pdf.svg'
import strategyIcon from '../graphics/strategy.svg'
import platformIcon from '../graphics/platform.svg'
import communicationIcon from '../graphics/communication.svg'

const Landing = ({ data }) => {
  const initialState = {
    name: '',
    email: ''
  }
  const [formData, setFormData] = useState(initialState)
  const [canSubmit, setCanSubmit] = useState(false)
  const [didSubmit, setDidSubmit] = useState(false)
  const emailInputElement = useRef(null)
  const downloadAnchorElement = useRef(null)
  function didChangeForm() {
    if (formData.name !== '' && emailInputElement.current.validity.valid) {
      setCanSubmit(true)
    } else {
      setCanSubmit(false)
    }
  }
  return (
    <React.Fragment>
      <SEO title="Brand Launch Strategy" />
      <FullWidthWrapper minHeight="100vh">
        <Image
          fluid={data.heroImage.childImageSharp.fluid}
          alt=""
          css={`
            height: 150vh;
            min-height: 100vh;
            @media (min-width: 1001px) and (max-height: 1000px) {
              height: 175vh;
            }
            @media (max-width: 1000px) and (max-height: 1000px) {
              height: 225vh;
            }
            @media (max-height: 700px) {
              height: 275vh;
            }
            @media (max-height: 500px) {
              height: 325vh;
            }
          `}
        />
        <Overlay alpha={0.4} />
        <FlexBox
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          itemSpacing="10vh"
          css={`
            padding: 25vh 0;
            position: absolute;
            top: 0;
          `}
          useFlexHeight
        >
          <Heading />
          <ForegroundLayout useNaturalHeight>
            <FlexBox
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              itemSpacing="5vw"
              useFlexWidth
              useFlexHeight
              isResponsive
              breakPoint={1000}
            >
              <FlexBox
                flexDirection="column"
                justifyContent="flex-start"
                itemSpacing="2rem"
                useFlexWidth
              >
                <StyledParagraph>
                  In this exclusive guide, we show you how to develop your brand
                  in three phases:
                </StyledParagraph>
                <StyledList>
                  <FlexBox
                    as="li"
                    flexDirection="row"
                    alignItems="flex-start"
                    itemSpacing="1rem"
                    useFlexHeight
                  >
                    <PhaseIcon src={strategyIcon} alt="Strategy" />
                    <FlexBox flexDirection="column" itemSpacing="1rem">
                      <StyledMediumHeading>Strategy</StyledMediumHeading>
                      <StyledParagraph>
                        Develop a strategic marketing plan that answers who
                        you’re targeting, where you’re targeting them, and the
                        message you’re communicating.
                      </StyledParagraph>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox
                    as="li"
                    flexDirection="row"
                    alignItems="flex-start"
                    itemSpacing="1rem"
                    useFlexHeight
                  >
                    <PhaseIcon src={platformIcon} alt="Platform" />
                    <FlexBox flexDirection="column" itemSpacing="1rem">
                      <StyledMediumHeading>Platform</StyledMediumHeading>
                      <StyledParagraph>
                        Create sales funnels using your unique value proposition
                        to generate conversions on a custom website or app.
                      </StyledParagraph>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox
                    as="li"
                    flexDirection="row"
                    alignItems="flex-start"
                    itemSpacing="1rem"
                    useFlexHeight
                  >
                    <PhaseIcon src={communicationIcon} alt="Communication" />
                    <FlexBox flexDirection="column" itemSpacing="1rem">
                      <StyledMediumHeading>Communication</StyledMediumHeading>
                      <StyledParagraph>
                        Introduce your brand to the market with brand films,
                        social video content, location advertising, and paid
                        sponsorships.
                      </StyledParagraph>
                    </FlexBox>
                  </FlexBox>
                </StyledList>
              </FlexBox>

              <FlexBox
                as="form"
                id="landing-form"
                flexDirection="column"
                alignItems="center"
                itemSpacing="1rem"
                onSubmit={event => {
                  event.preventDefault()
                  downloadAnchorElement.current.click()
                  if (typeof window !== 'undefined') {
                    if (
                      process.env.NODE_ENV === `production` &&
                      typeof fbq === `function`
                    ) {
                      // eslint-disable-next-line no-undef
                      fbq('track', 'Lead')
                      // eslint-disable-next-line no-undef
                      gtag('event', 'generate_lead', { event_label: 'Lead' })
                      setDidSubmit(true)
                    }
                    const url =
                      'https://script.google.com/macros/s/AKfycbxu0T-DMh0NK8C94HZinUCtO9RDLXU8Sq5WotvKlqeKj3Q4PaA/exec'
                    const config = {
                      method: 'POST',
                      mode: 'cors',
                      credentials: 'omit',
                      headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                      },
                      referrer: 'no-referrer',
                      body: qs.stringify(formData, { arrayFormat: 'comma' }) // best format for google scripts
                    }
                    window.fetch(url, config).then(response => response.json())
                  }
                }}
                useFlexHeight
                isResponsive
              >
                <TextInput
                  type="text"
                  name="name"
                  required
                  aria-label="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={event => {
                    setFormData({
                      ...formData,
                      [event.target.name]: event.target.value
                    })
                    didChangeForm()
                  }}
                />
                <TextInput
                  ref={emailInputElement}
                  type="email"
                  name="email"
                  required
                  aria-label="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={event => {
                    setFormData({
                      ...formData,
                      [event.target.name]: event.target.value
                    })
                    didChangeForm()
                  }}
                />
                <SubmitButton disabled={!canSubmit}>
                  <ButtonIcon src={pdfIcon} alt="PDF" />
                  Get It
                </SubmitButton>
              </FlexBox>
            </FlexBox>
          </ForegroundLayout>
        </FlexBox>
      </FullWidthWrapper>
      <AngledDivider color="#1b1b1b" position="top" />
      <ContentBlock backgroundColor="#1b1b1b">
        <MediumHeading>We Are Orange Astronaut</MediumHeading>
        <Paragraph>
          Orange Astronaut is a full-service creative agency that specializes in
          advertisement, marketing and digital intelligence services. We’re data
          driven creators, developers and marketers who understand the science
          behind brand growth. We combine strategic marketing with remarkable
          cinematography and new technologies to grow your brand.
        </Paragraph>
      </ContentBlock>
      <AngledDivider color="#1b1b1b" position="bottom" />
      <FullWidthWrapper
        css={`
          margin-top: 10vw;
        `}
      >
        <ForegroundLayout
          css={`
            padding: 2rem 0;
          `}
        >
          <SmallPrint>
            {legalLinks.map((link, index) => (
              <StyledLink key={index} to={link.url}>
                {link.title}
              </StyledLink>
            ))}
            <Copyright>
              © 2019 Orange Astronaut, LLC. All rights reserved.
            </Copyright>
          </SmallPrint>
        </ForegroundLayout>
      </FullWidthWrapper>
      <a
        ref={downloadAnchorElement}
        href="/OrangeAstronautBrandingPacket.pdf"
        download
        css={`
          display: none;
        `}
      >
        Download
      </a>
      {didSubmit && (
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=532930&conversionId=1147274&fmt=gif"
        />
      )}
    </React.Fragment>
  )
}

const Heading = () => (
  <WordArt.Wrapper
    css={`
      max-width: 80vw;
      text-align: center;
      height: unset;
    `}
  >
    <WordArt.Line color="#fff" fontSize="1em">
      The Secret Sauce
    </WordArt.Line>
  </WordArt.Wrapper>
)

const StyledList = styled('ul')`
  padding: 0;
  list-style: none;
`

const PhaseIcon = styled('img')`
  width: 50px;
`

const ButtonIcon = styled('img')`
  position: relative;
  top: -3px;
  width: 35px;
  margin-right: 0.5rem;
`

const StyledMediumHeading = styled(MediumHeading)`
  margin-top: 0.6rem;
`

const StyledParagraph = styled(Paragraph)`
  margin-top: 0;
`

const TextInput = styled.input`
  font-size: 18px;
  font-weight: 300;
  padding: 0 25px;
  min-width: 300px;
  height: 50px;
  border: 0;
  border-radius: 25px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  transition: background-color 100ms linear;
  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.9);
  }
  &::placeholder {
    color: #bbb;
    opacity: 1;
  }
`

const SubmitButton = styled.button`
  position: relative;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  flex: none;
  padding: 5px 20px 0;
  height: 50px;
  border-radius: 25px;
  border: 0;
  background-color: ${props => (props.disabled ? '#666' : '#f93616')};
  color: ${props => (props.disabled ? '#ccc' : '#fff')};
  font-size: 20px;
  font-family: 'Prompt', sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: transform 100ms ease-out;
  &:hover {
    transform: ${props => (props.disabled ? 'none' : 'scale(1.05)')};
  }
`

const SmallPrint = styled(props => (
  <FlexBox flexDirection="row" itemSpacing="1rem" isResponsive {...props} />
))`
  & > * {
    display: inline-block;
  }
  & a {
    font-size: 16px;
  }
`

const StyledLink = styled(Link)`
  color: #888;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  transition: color 150ms linear;
  &:hover {
    color: #ddd;
  }
`

const Copyright = styled.p`
  display: inline-block;
  margin: 0;
  color: #888;
  font-weight: 400;
`

const legalLinks = [
  {
    title: 'Terms of Use',
    url: '/terms'
  },
  {
    title: 'Privacy Policy',
    url: '/privacy'
  }
]

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "space.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 5000, quality: 100, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Landing
